/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState } from 'react';
import twitter from '../assets/twitter-.png';
import teleg from '../assets/telegram.png';
import '../App.css';
import Footer from '../components/footer';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import logoPic from '../assets/logo.png';
import home from '../assets/home-button.png';
import { bsc } from 'wagmi/chains';
import Ticker3 from '../components/ticker3';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const tweet = () => {
	window.open("#");
}

const tg = () => {
	window.open("#");
}

const Presale = () => {

	const [_navbarOpen, set_navbarOpen] = useState(0)
	const [_percentage, setPercentage] = useState(0)
	

	async function closeNav() {
		set_navbarOpen(0);
		//this.setState({ _navbarOpen: 0 });
	}

	async function navbarOpen() {
		set_navbarOpen(1);
		//this.setState({ _navbarOpen: 0 });
	}

	useEffect(() => {

		setPercentage(80+'%');

	}, []);

	return (
		<div class="allWrap">
			<div class="light">
				<div class="cont2">
					{_navbarOpen < 1 ?
						(<div class="miniBarMain">
							<div class="logoDivMob"><img src={logoPic} onClick={() => window.location.href = '/'} /></div>

							<div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
						</div>) :
						(<div class="miniBarMain">
							<div class="logoDivMob"><img src={logoPic} onClick={() => window.location.href = '/'} /></div>

							<div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
						</div>)}

					<div class="headers">

						<div class="h1">

							<div class="connect">

								<div class="socialIcon">
									<img onClick={tweet} src={twitter} />
									<img onClick={tg} src={teleg} />
									<img onClick={() => window.location.href = '/'} src={home} />
								</div>

							</div>

							<div class="logoDiv"><img src={logoPic} onClick={() => window.location.href = '/'}></img></div>
							<div class="logoDivMob"><img src={logoPic} onClick={() => window.location.href = '/'} /></div>
							<div class="right">

								<div class="connect2">
									<button class="connectBtn">Connect</button>
								</div>

							</div>

						</div>

					</div>

					<Link to="gallery" spy={true} smooth={true} duration={550}>


						{_navbarOpen > 0 ?
							(<div class="littleNav">
								<div id="fontSize" onClick={() => window.location.href = '/'}>HOME</div>

								<div class="socialIcon">
									<img onClick={tweet} src={twitter} />
									<img onClick={tg} src={teleg} />
								</div>

							</div>) : null}
					</Link>

					<div class="presale">
						<div class="container">
							<h1 class="title">Token Presale</h1>
							<p class="description">Join our exclusive presale and be a part of the future!</p>
							<div class="progress-bar">
								<div class="progress" style={{ width: _percentage }}></div>
							</div>
							<div class="stats">
								<div class="stat">
									<h2>Tokens Sold</h2>
									<p>650,000</p>
								</div>
								<div class="stat">
									<h2>Tokens Available</h2>
									<p>1,000,000</p>
								</div>
							</div>
							<form class="purchase-form">
								<input type="number" placeholder="Number of tokens" required />
								<button type="submit">Buy Now</button>
							</form>
						</div>
					</div>

				</div>

				<Ticker3 />

				<Footer />

			</div >
		</div >
	)

}
export default Presale;
